import { ProductDataLoader } from '../ProductDataLoader'
import type{ WCSConfig, Locale } from '../../types/core'
import { ProductList } from '../../types/ProductList'
import type { AuthenticatedFetch }from '../../util/authenticatedFetch'
import { Product } from '../../types/Product'
import { ByPartNumbers } from './types/ByPartNumberResponse'
import getGenderFromAttributes from '../../util/getGenderFromAttributes'

export type ByPartNumbersDataLoaderOptions = {
  partNumbers: string[]
}

export class ByPartNumbersDataLoader implements ProductDataLoader<ByPartNumbersDataLoaderOptions> {
  constructor(
    private wcsConfig: WCSConfig,
    private locale: Locale,
    private authenticatedFetch: AuthenticatedFetch<ByPartNumbers.Response>) { }

  private getUrl(partNumbers: string[]): string {
    const brandLocale = `${this.wcsConfig.brand}_${this.locale.country}`
    return (`search/resources/store/${brandLocale}/productview/summary/byPartNumbers?locale=${this.locale.locale}&`
      + partNumbers.map((pn) => `pn=${pn}`).join('&'))
  }

  private mapResult(partNumbers:string[], rawResponse: ByPartNumbers.Response): ProductList {
    const productsIndex = rawResponse?.products?.reduce((acc, product)=> {
      if(product?.badges?.find(badge => badge.key === 'BADGE_SOLD_OUT')) {
        return acc
      }
      return {
        ...acc,
        [product.partNumber]:this.mapProduct(product)
      }
    }, {})

    const products = partNumbers.reduce((acc, partNumber)=> {
      const product = productsIndex[partNumber]
      return product ? acc.concat(product) : acc
    }, [])

    return {
      dataSource: 'ByPartNumbers',
      products
    }
  }

  private mapProduct(product: ByPartNumbers.Product): Product {
    return {
      externalId: product.productId,
      designerIdentifier: product.designerIdentifier,
      designerName: product.designerNameEN,
      price: {
        sellingPrice: product.price?.sellingPrice,
        rdSellingPrice: product.price?.rdSellingPrice,
        currency: product.price?.currency
      },
      thumbnailUrlTemplate: product.imageTemplate,
      name: product.name,
      productUrl: product.seo.seoURLKeyword,
      imageViews: product.imageViews,
      buyable: true,
      displayable: true,
      partNumber: product.partNumber,
      attributes: {
        gender: getGenderFromAttributes(product.attributes),
      },
    }
  }

  async load(options: ByPartNumbersDataLoaderOptions): Promise<ProductList> {
    const result = await this.authenticatedFetch(this.getUrl(options.partNumbers))
    return this.mapResult(options.partNumbers, result)
  }
}
