import React from 'react'
import { createComponent } from '@ycos/fela'
import { fadeInAnimation } from './Animaton'

export const CardWrapper = createComponent(
  'CardContainer',
  ({ theme, position }) => ({
      padding: `${theme.spacingMultiplier * 3}px 12px`,
      scrollSnapAlign: 'start',
      'screen-xlarge': {
        flex: '1 0 16.6%',
      },
      'screen-large': {
        flex: '1 0 20%',
        padding: `${theme.spacingMultiplier * 3}px 10px`,
      },
      flex: 'auto',
      ...(position < 8 ? {
        ...fadeInAnimation,
        animationDelay: ((position + 1) * 0.1) + 's',                
      } : {})
    })
  ,
  'div'
)
export const DesktopOnlyCardContainer = createComponent(
  'DesktopOnlyCardContainer',
  ({ theme }) => ({
    scrollSnapAlign: 'start',
    display:'none',
    'screen-large':{
      display:'block',
      flex: '1 0 20%',
      padding: `${theme.spacingMultiplier * 3}px 0`
    },
    'screen-xlarge':{
      flex: '1 0 16.6%'
    },
  }),
  'div'
)

