import type  { GuestUser, RegisteredUser, User } from "./types";
import uuid from 'uuid'

export class UserLoader {
    static USER_SUMMARY_KEY = "userSummary"
    static VISITOR_KEY = "opt_fs_visitorId"

    private attemptGetUser(): RegisteredUser | null {
        try {
            const user = JSON.parse(localStorage.getItem(UserLoader.USER_SUMMARY_KEY))
            if(!user.isSignedIn) {
                return null
            }
            user.type = 'registered'
            user.hasOrder = user.hasOrder === 'true'
            user.id = this.getVisitorId()
            return user
        } catch (_){
            return null
        }
    }
    private getVisitorId(){
        let id = localStorage.getItem(UserLoader.VISITOR_KEY)
        if(!id) {
            id = uuid()
            localStorage.setItem(UserLoader.VISITOR_KEY, id)
        }
        return id
    }
    private getGuestUser(): GuestUser {
        return {
            type: 'guest',
            isSignedIn: false,
            id:this.getVisitorId()
        }
    }

    async load(): Promise<User> {
        let user = this.attemptGetUser()
        if(user) {
            return user
        } else {
            return this.getGuestUser()
        }        
    }
}