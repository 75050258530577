import { ProductAttribute } from '../dataLoaders/shared'

const GENDER_MAPPER: Record<string, 'womens' | 'mens'> = {
  Woman: 'womens',
  Man: 'mens',
}

export default (attributes: ProductAttribute[] = []) => {
  const value = attributes.find(attr => attr.identifier === 'Gender')?.values[0]?.identifier

  return GENDER_MAPPER[value]
}
