import React from 'react'
import { createComponent } from '@ycos/fela'
import { BigHeader, BodyText, LinkText, LinkWrapper } from './TextComponents'
import { fadeInAnimation } from './Animaton'

export const MobileCard = createComponent(
  'MobileCard',
  ({ theme }) => ({
    alignSelf: 'center',
    width: '100%',
    alignItems: 'start',
    padding: `${theme.spacingMultiplier * 2}px ${theme.spacingMultiplier * 3}px 0 ${theme.spacingMultiplier * 3}px`,
    ...fadeInAnimation
  }),
  'div'
)

export const MobileTextCardContainer = createComponent(
  'MobileTextCardContainer',
  () => ({
    maxWidth: "min(100vw, 1376px)",
    'screen-large': {
      display: 'none'
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderTop: '1px solid #e9e9e9',
  }),
  'div'
)

export const TextWrapper = createComponent(
  'TextWrapper',
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: `${theme.spacingMultiplier}px`
  }),
  'div'
)
interface MobileTextCardProps {
  titleText?: string,
  personalisedText?: string,
  linkText?: string,
  href: string,
  onClick?: () => void
}

export const MobileTextCard: React.FC<MobileTextCardProps> = ({ titleText, personalisedText, href, linkText, onClick }) => (
  <MobileTextCardContainer data-automation='mobile-text-card'>
    <MobileCard>
      {titleText && (
        <BigHeader data-automation='title-text'>
          {titleText}
        </BigHeader>
      )}
      <TextWrapper>
        {personalisedText && (
          <BodyText data-automation='personalised-text'>
            {personalisedText}
          </BodyText>
        )}

        {linkText && href && (
          <LinkWrapper data-automation='link-text' href={href} onClick={onClick}>
            <LinkText>
              {linkText}
            </LinkText>
          </LinkWrapper>
        )}
      </TextWrapper>
    </MobileCard>
  </MobileTextCardContainer>
)
