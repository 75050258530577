import React from 'react';
import { createComponent } from '@ycos/fela'
import { createFontStyle} from '@ycos/primitives'
import { Product } from '../../domain/types/Product'
import { Locale } from '../../domain/types/core';

export interface ProductCardProps {
  product: Product,
  locale: Locale,
  onProductClick: () => void
}
  
const Card = createComponent(
    'Card',
    ({theme}) => ({
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      'screen-large': {
        width: '100%'
      },
      width: `134px`,
      backgroundColor : theme.typography.colors.PrimaryWhite,
      height: '100%',      
    }),
    'div'
  )

  const ImageWrapper = createComponent(
    'ImageWrapper',
    ({theme}) => ({      
      marginBottom: `${theme.spacingMultiplier * 2}px`,
      display: 'block',
      flexGrow: 1,
      width: '100%',
      paddingBottom:'150%',
      position: 'relative',
      backgroundColor:theme.typography.colors.SecondaryLightestGrey
    }),
    'div'
  )

const ImageSpacer = createComponent(
  'ImageWrapper',
  ({ theme }) => ({
    padding: `${theme.spacingMultiplier * 2}px ${theme.spacingMultiplier * 2}px 0px`,
    'screen-large': {
      padding: `${theme.spacingMultiplier * 3}px ${theme.spacingMultiplier * 3}px ${theme.spacingMultiplier}px`    },
  }),
  'div'
)

  const DesignerName = createFontStyle(
    'DesignerName',
    {type: 'Heading', tagName: 'div', name: 'B6'},
    ({ theme }) => ({
      color: theme.typography.colors.PrimaryBlack,    
      fontSize: '16px',
      margin: `0px ${theme.spacingMultiplier * 2}px ${theme.spacingMultiplier * 2}px ${theme.spacingMultiplier * 2}px`,
      fontWeight: 'bold',
      overflow:'hidden',
      textOverflow: "ellipsis",
      letterSpacing: '1',
      'screen-large':{
        fontSize: '16px',
        marginBottom:'16px',      
      }
    })
  )

const Image = createComponent(
  'Image',
  () => ({
    width: '100%',
    position: 'absolute',
    top: 0, left: 0, right: 0, bottom: 0
  }),
  'img'
)

const ProductCard: React.FC<ProductCardProps> = ({product, locale, onProductClick}) => {
    return (
    
      <Card data-automation='product-card' onClick={onProductClick}>
        <a 
          data-automation='product-card-link'
          href={`/${locale.id}/shop/product${product.productUrl}`}
        >
          <ImageSpacer>
            <ImageWrapper>
              <Image
              data-automation='product-image'
              src={product.thumbnailUrlTemplate.replace('{width}', '250').replace('{view}',product.imageViews[0])}
              />
            </ImageWrapper>
          </ImageSpacer>
          <DesignerName data-automation='designer-name'>{product.designerName}</DesignerName> 
        </a>
      </Card>
    
    )
}

export default ProductCard;