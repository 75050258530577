import { CompositeProductListAnalytics } from "../analytics/CompositeProductListAnalytics";
import GTMProductListAnalytics from "../analytics/gtm/GTMProductListAnalytics";
import type { OptimizelyUserAttributes } from "../analytics/optimizely/OptimizelyProductListAnalytics";
import { ProductListAnalytics } from "../analytics/ProductListAnalytics";
import { CategoryProductDataLoader } from "../dataLoaders";
import { ProductList } from "../types/ProductList";
import { User } from "./types";
import { UserLoader } from "./UserLoader";
import { PersonalisedProductDataLoader } from "../dataLoaders/personalised/PersonalisedProductDataLoader";
import { OptimizelyFactory } from "./OptimizelyFlow";

export type PersonalisationFlowResult = {
  analytics: ProductListAnalytics,
  productList: ProductList,
  user: User,
}

export type BrowserAttributes = {
  breakpoint: string,
  hostname: string,
  locale: string,
  estore: string,
}

export type PersonalisationFlowOptions = {
  disabled: boolean,
  fallbackCategory: string,
}
export class PersonalisationFlow {
  constructor(
    private userLoader:UserLoader,
    private categoryProductDataLoader: CategoryProductDataLoader,
    private personalisedProductDataLoader: PersonalisedProductDataLoader,
    private gtmAnalytics:GTMProductListAnalytics,
    private optimizelyFactory: OptimizelyFactory,
    private browserAttributes:BrowserAttributes
  ) { }

  private getOptimizelyUserAttrbutes(user:User):OptimizelyUserAttributes {
    return {
        id:user.id,
        is_logged_in: user.isSignedIn,
        has_purchased: user.type === 'registered' ? user.hasOrder : false,
        ...this.browserAttributes
    }
  }

  private createAnalytics(userAttributes:OptimizelyUserAttributes):ProductListAnalytics  {
    return new CompositeProductListAnalytics([
      this.gtmAnalytics,
      this.optimizelyFactory.createAnalytics(userAttributes)
    ])
  }

  getProductsByCategory(category: string = '/just-in'): Promise<ProductList>{
    return this.categoryProductDataLoader.load({ category })
  }

  createOptimizelyFlow(userAttributes:OptimizelyUserAttributes) {
    return this.optimizelyFactory.createOptimizelyFlow(
      userAttributes,
      userAttributes.is_logged_in ? 'just_in_carousel_registered' : 'just_in_carousel_guest',
      userAttributes.is_logged_in ? 'product_recommendations_registered' : 'product_recommendations_guest'
    )
  }

  async run(options?: PersonalisationFlowOptions): Promise<PersonalisationFlowResult> {
    const user = await this.userLoader.load()
    const userAttributes = this.getOptimizelyUserAttrbutes(user)
    const analytics = this.createAnalytics(userAttributes)
    const customerId = user.isSignedIn ? user.parentCustomerURN : 'guest_user_ton'
    const optimizelyFlow = this.createOptimizelyFlow(userAttributes)

    try {
      if (options?.disabled) {
        const productList = await this.getProductsByCategory(options?.fallbackCategory)

        return { productList, user, analytics }
      }

      await optimizelyFlow.onReady()
      const algorithm = optimizelyFlow.getAlgorithm()

      if (algorithm === 'fallback') {
          const productList = await this.getProductsByCategory(options?.fallbackCategory)

          optimizelyFlow.activate()

          return { productList, user, analytics }
      }

      const productList = await this.personalisedProductDataLoader.load({
        algorithm: algorithm,
        customerId
      })

      optimizelyFlow.activate()

      return { productList, user, analytics }
    } catch (e) {
      const productList = await this.getProductsByCategory(options?.fallbackCategory)

      optimizelyFlow.activateFallback('fallback')

      return { productList, user, analytics }
    }
  }
}
