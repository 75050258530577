import React from 'react'

export default ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <g fill={color} fillRule="nonzero">
        <g>
          <path
            d="M23.688 0.868L24.588 1.768 9.99 16.348 24.588 30.928 23.688 31.828 8.19 16.348z"
            transform="translate(-951 -371) translate(951 371) rotate(-180 16.389 16.348)"
          />
        </g>
      </g>
    </g>
  </svg>
)
