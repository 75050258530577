import { createComponent } from '@ycos/fela'
import React from 'react'
import { shimmerAnimation } from './Animaton'
import { TextWrapper, MobileCard, MobileTextCardContainer} from './MobileTextCard'

const Wrapper = createComponent(
  'Wrapper',
  ({ theme }) => ({
    display: 'flex',
    maxWidth: 'min(100vw, 1376px)',
    minHeight:"250px",
    backgroundColor: theme.typography.colors.SecondaryLightestGrey,
    ...shimmerAnimation,    
    'screen-large': {
      margin: `0 ${theme.spacingMultiplier * 4}px`,  
      paddingTop: '30.2%'  
    },
    'screen-xlarge':{
      paddingTop:'25.6%'
    }
  }),
  'div'
)

const TextBox = createComponent(
  'TextBox',
  ({ theme, textheight, textwidth, marginBottom, marginTop=0 }) => ({
    marginBottom: `${marginBottom}px`,
    marginTop: `${marginTop}px`,
    height: `${theme.spacingMultiplier * textheight}px`,
    width: textwidth,
    backgroundColor: theme.typography.colors.SecondaryLightestGrey,
    ...shimmerAnimation
  }),
  'div'
)

const Skeleton = () => {
  return (
    <>
      <MobileTextCardContainer>
          <MobileCard>              
              <TextBox textheight={5} textwidth={'130px'} marginTop={'8'} marginBottom={'16'}/>
              <TextWrapper>
                <TextBox textheight={1} textwidth={'250px'} marginBottom={'8'} />
                <TextBox textheight={1} textwidth={'50'} marginBottom={'8'} />                
              </TextWrapper>
          </MobileCard>
      </MobileTextCardContainer>
      <Wrapper/>
    </>
  )
}

export default Skeleton
