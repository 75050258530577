import { Product } from "../types/Product";
import { ProductList } from "../types/ProductList";
import { ProductListAnalytics } from "./ProductListAnalytics";


export class CompositeProductListAnalytics implements ProductListAnalytics {
    
    constructor(private productListAnalytics:Array<ProductListAnalytics>){}

    private withAnalytics(fn:(productListAnalytics:ProductListAnalytics) => void) {
        this.productListAnalytics.forEach((instance)=> {
            try {
                fn(instance)
            } catch(e) {
                console.log(e)
            }
        })
    }
    onProductLoad(products: Product[], productList: ProductList, productListComponent: string): void {
        this.withAnalytics(p => p.onProductLoad(products, productList, productListComponent))
    }
    onProductClick(product: Product, index: number, productList: ProductList, productListComponent: string): void {
        this.withAnalytics(p => p.onProductClick(product, index, productList, productListComponent))
    }
    onArrowClick(dir: "next" | "previous"): void {
        this.withAnalytics(p => p.onArrowClick(dir))
    }
    onCTAClick(): void {
        this.withAnalytics(p => p.onCTAClick())
    }

}