
export const fadeIn = () => ({
    '0%': {
        'opacity': 0
    },
    '100%': {
        'opacity': 1
    }
})

export const fadeInAnimation = {
    animationName: fadeIn(),
    animationDuration: '0.6s',
    animationTimingFunction: 'ease-in-out',
    animationFillMode: 'both'
}

const shimmer = () => ({
    '0%': {
        'background-position': `-80vw 0`
    },
    '100%': {
        'background-position': `80vw 0`
    }
})

export const shimmerAnimation = {
    backgroundImage: 'linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: "700px 504px",
    animationName: shimmer(),
    animationDuration: `2000ms`,
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
    animationFillMode: 'forwards',
}

 