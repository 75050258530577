import { ProductDataLoader } from '../ProductDataLoader'
import type { PersonalisationConfig } from '../../types/core'
import { ProductList, MIN_PRODUCTS } from '../../types/ProductList'
import { ByPartNumbersDataLoader } from '../by-part-number/ByPartNumbersDataLoader'
import type { PersonalisedProducts } from './types/PersonalisedProductDataResponse'

const MAX_PART_NUMBERS = 24
const MAX_PRODUCTS = 16

export type PersonalisedProductDataLoaderOptions = {
  customerId:string
  algorithm:string
}
export class PersonalisedProductDataLoader implements ProductDataLoader<PersonalisedProductDataLoaderOptions> {
  constructor(
    private personalisationConfig: PersonalisationConfig,
    private byPartNumbersDataLoader: ByPartNumbersDataLoader) {}

  private getUrl({ customerId, algorithm }: PersonalisedProductDataLoaderOptions): string {    
    return `${this.personalisationConfig.baseUrl}/products/${algorithm}/${customerId.replace(/:/g, '-')}.json`
  }
  private async fetchProductSuggestions(options:PersonalisedProductDataLoaderOptions): Promise<PersonalisedProducts.Response> {
    const res = await fetch(this.getUrl(options))
    return await res.json()
  }
  async load(options: PersonalisedProductDataLoaderOptions): Promise<ProductList> {    
    const productSuggestion = await this.fetchProductSuggestions(options)
    const trimmedPartnumbers = productSuggestion.products.slice(0,MAX_PART_NUMBERS)
    const productList = await this.byPartNumbersDataLoader.load({partNumbers: trimmedPartnumbers})
    const trimmedProductList = productList.products.slice(0,MAX_PRODUCTS)
    
    if(productList.products.length < MIN_PRODUCTS) {
      throw new Error('Too Few Products')
    }
    productList.dataSource = 'PersonalisedProducts'
    productList.algorithm = options.algorithm
    productList.products = trimmedProductList
    return productList
  }
}
