import { OptimizelyProductListAnalytics, OptimizelyUserAttributes } from "../analytics/optimizely/OptimizelyProductListAnalytics";
import type { Client as OptimizelyClient } from "@optimizely/optimizely-sdk"

export class OptimizelyFactory {
    constructor( private optimizely:OptimizelyClient ) {}

    createOptimizelyFlow(
        userAttributes: OptimizelyUserAttributes, experimentKey: string, featureKey: string): OptimizelyFlow {
            return new OptimizelyFlow(this.optimizely, userAttributes, experimentKey, featureKey)
        }
    
    createAnalytics(userAttributes:OptimizelyUserAttributes): OptimizelyProductListAnalytics {
        return new OptimizelyProductListAnalytics(this.optimizely, userAttributes)
    }
}
export class OptimizelyFlow {
    constructor(
        private optimizely:OptimizelyClient,
        private userAttributes: OptimizelyUserAttributes, 
        private experimentKey:string,
        private featureKey:string){ }
    
    onReady(){
        return this.optimizely.onReady()
    }

    activate(){
        this.optimizely.activate(this.experimentKey, this.userAttributes.id, this.userAttributes)   
    }
    
    detectForcedVariation(){
        const possibleVariation = new URLSearchParams(window.location.search).get('opt_fs_variation')
        if(["recommended", "random", "fallback"].includes(possibleVariation)) {
            this.optimizely.setForcedVariation(this.experimentKey, this.userAttributes.id, possibleVariation)
        }
        return possibleVariation
    }

    getAlgorithm() {
        this.detectForcedVariation()
        const { algorithm } = this.optimizely.getAllFeatureVariables(
            this.featureKey, this.userAttributes.id, this.userAttributes)
        if (!!algorithm && typeof algorithm === 'string') {
            return algorithm
        }        
        throw new Error("No algorithm found")        
    }

    activateFallback(fallbackVariation){
        try {
            this.optimizely.setForcedVariation(this.experimentKey, this.userAttributes.id, fallbackVariation)
            this.optimizely.activate(this.experimentKey, this.userAttributes.id, this.userAttributes)
        } catch (_) {
            //swallow
        }  
    }
}