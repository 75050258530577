import React, { useEffect } from 'react'
import ProductList from './index'
import Skeleton from './Skeleton'
import { createProductLoaderSDK } from "../../domain/createProductLoaderSDK";
import type { PersonalisationFlowResult } from "../../domain/personalisation-flow/PersonalisationFlow";
import { Locale, OptimizelyConfig, PersonalisationConfig, WCSConfig } from "../../domain/types/core";
import getBreakpoint from '../../utils/getBreakpoint'

export type ProductCardList = {
  layoutVariant: string[]
}

const getPersonalisedText = (messages, firstName?, algorithm?) => {
  if (!firstName || !algorithm) return messages.guestUser;
  return algorithm === 'rec' ? messages.loggedInRecommended.replace('{firstName}', firstName) : messages.loggedInRandom.replace('{firstName}', firstName)
}

const getMessages = (locale: Locale) => require(`./messages/${locale.language}.json`)

export type ProductListProps = {
  config:{
    wcs:{
    externalBaseUrl:string,
    clientId:string
  }},
  locale:Locale,
  personalisation:PersonalisationConfig,
  optimizely:OptimizelyConfig,
  tonShop?: string,
}

const CMProductList: React.FC<ProductListProps> & ProductCardList = ({ config, locale, personalisation, optimizely, tonShop }) => {

  const tonWCSConfig = {
    baseUrl: config.wcs.externalBaseUrl,
    clientId: config.wcs.clientId,
    brand: 'theoutnet'
  }

  const [carouselCopy, setCarouselCopy] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(true)
  const [error, setError] = React.useState()
  const [personalisationResult, setPersonalisationResult] = React.useState<PersonalisationFlowResult>()
  const targetHref = tonShop === 'mens' ? `/${locale.id}/shop/mens/just-in` : `/${locale.id}/shop/just-in`

  useEffect(() => {
    const breakpoint = getBreakpoint(window.innerWidth)
    const hostname = window.location.hostname
    const productLoaderSDK = createProductLoaderSDK(
      tonWCSConfig,
      locale,
      personalisation,
      optimizely,
      { location:'homepage' },
      breakpoint,
      hostname
    )

    productLoaderSDK.getPersonalisationFlow(tonShop)
      .then((personalisationResult:PersonalisationFlowResult)=> {
        setPersonalisationResult(personalisationResult)
        setIsLoading(false)
      }).catch((e)=> {
        setError(e)
      })
  }, [])

  useEffect(() => {
    const messages = getMessages(locale)
    if (personalisationResult && personalisationResult.user) {
      setCarouselCopy({
        titleText: messages.header,
        linkText: messages.CTA,
        personalisedText: getPersonalisedText(
          messages,
          personalisationResult.user.type === 'registered' ? personalisationResult.user.firstName : null,
          personalisationResult.productList.algorithm)
      })
    }
  }, [personalisationResult])

  if (error) {
    return null
  }

  return isLoading ? <Skeleton /> : (
    <ProductList
      locale={locale}
      carouselCopy={carouselCopy}
      tracking={personalisationResult.analytics}
      productList={personalisationResult.productList}
      targetHref={targetHref}
    />
  )
}

CMProductList.layoutVariant = ['osc-curated-carousel']

export default CMProductList
