import { Product } from "../../types/Product";
import { ProductList } from "../../types/ProductList";
import { ProductListAnalytics } from "../ProductListAnalytics";
import type { Client as OptimizelyClient } from "@optimizely/optimizely-sdk"

export type OptimizelyUserAttributes = {
    id:string,
    is_logged_in:boolean
    has_purchased: boolean | undefined
    breakpoint: string
    hostname: string
    locale: string
}
export class OptimizelyProductListAnalytics implements ProductListAnalytics {

    constructor(
        private optimizelySdk: OptimizelyClient,
        private userAttributes:OptimizelyUserAttributes
    ){ }

    onProductLoad(products: Product[], productList: ProductList, productListComponent: string): void { }

    onProductClick(product: Product, index: number, productList: ProductList, productListComponent: string): void {
        this.optimizelySdk.track('product-click', this.userAttributes.id, this.userAttributes)
    }
    
    onArrowClick(dir: "next" | "previous"): void { }

    onCTAClick(): void {
        this.optimizelySdk.track('product-list-view-all', this.userAttributes.id, this.userAttributes)        
    }
}