import React from 'react'

export default ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <g fill={color} fillRule="nonzero">
        <g>
          <path d="M21.688 0.868L22.588 1.768 7.99 16.348 22.588 30.928 21.688 31.828 6.19 16.348z" transform="translate(-329 -371) translate(329 371)" />
        </g>
      </g>
    </g>
  </svg>
)
