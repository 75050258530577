
export type AuthenticatedFetch<T> = (url:string) => Promise<T>

export const createAuthenticatedFetch = (wcsConfig)=> async <T>(url): Promise<T> => {
  const constructedUrl = `${wcsConfig.baseUrl}${url}`
  const response = await fetch(constructedUrl, {
    headers: {
      'X-IBM-Client-Id': wcsConfig.clientId,
      'Content-Type': 'application/json'
    }
  })

  return await response.json()
}
