import { createComponent } from '@ycos/fela'
import { createFontStyle } from '@ycos/primitives'

  export const BodyText = createFontStyle(
    'BodyText',
    {type: 'Paragraph', tagName: 'p', name: '4'},
    ({ theme }) => ({
        marginTop:`${theme.spacingMultiplier}px !important`,        
        color: theme.typography.colors.PrimaryBlack,
        lineHeight: '1.38',
        letterSpacing: 'normal',
        marginBlockStart:'0',
        marginBlockEnd: '0',
    }),
  )

  export const BigHeader = createFontStyle(
    'BigHeader',
    {type: 'Paragraph', tagName: 'p', name: '4'},
    ({ theme }) => ({
        color: theme.typography.colors.PrimaryBlack,
        textTransform:"uppercase",
        lineHeight: 'normal',
        letterSpacing: '1.2px',
        fontSize: '40px',
        marginBlockStart: '0',
        marginBlockEnd: '0',
    }),
  )

  export const LinkWrapper = createComponent(
    'LinkCTA', 
    () => ({
    }),
    'a', ['onClick']
    )

  export const LinkText = createFontStyle(
    'LinkText', 
    {type: 'Paragraph', tagName: 'p', name: '3'},
    ({ theme }) => ({
      margin: `${theme.spacingMultiplier}px 0`,
      fontSize: '16px',
      lineHeight: '22px',
      color: theme.typography.colors.PrimaryBlack,
      letterSpacing: 'normal',
      textDecoration: 'underline'
  }),
  )