import { Product } from '../../types/Product'
import { ProductList } from '../../types/ProductList'
import type { GTMTracking } from './types/GTMTracking'
import { ProductListAnalytics } from '../ProductListAnalytics'
import type { GTMConfig } from '../../types/core'

export default class GTMProductListAnalytics implements ProductListAnalytics {
    constructor(
        private gtmConfig: GTMConfig,
        private track: GTMTracking) { }

    private buildProductsDataObject(products: Product[], productSuggestionType: string, productListComponent:string) {
        return products.map((product, index) => ({
            category: {
                primaryCategory: product.category?.label,
                subCategory1: product.category?.child?.label,
                subCategory2: ''
            },
            price: {
                currency: product?.price?.currency?.label,
                displayPrice: product?.price?.rdSellingPrice?.amount / product?.price?.rdSellingPrice?.divisor
            },
            productInfo: {
                productID: product.partNumber,
                productName: product.name,
                manufacturer: product.designerName,
                ...(product?.attributes?.gender ? { gender: product.attributes?.gender } : {})
            },
            attributes: {
                position: index + 1,
                recType: productSuggestionType,
                recId: 'just in v1'
            }
        }))
    }

    onProductLoad(products: Product[], productList: ProductList, productListComponent: string): void {
        const productsData = this.buildProductsDataObject(
            products, productList?.algorithm, productListComponent)
        this.track({
            event: 'product list module view',
            listModule: {
                attributes: {
                    name: 'just in',
                    type: productListComponent,
                    recType: productList?.algorithm,
                    recId: 'just in v1',
                    placement: this.gtmConfig.location
                },
                item: productsData
            }
        })
    }

    onProductClick(product: Product, index: number, productList: ProductList, productListComponent: string): void {
        const { name, partNumber, category, badges, price, designerName, attributes = {} } = product
        this.track({
            event: `product list module select`,
            listModule: {
                attributes: {
                    name: 'just in',
                    type: productListComponent,
                    recType: productList?.algorithm,
                    recId: 'just in v1',
                    placement: this.gtmConfig.location
                },
                item: [
                    {
                        category: {
                            primaryCategory: category?.label,
                            subCategory1: category?.child?.label,
                            subCategory2: ''
                        },
                        price: {
                            currency: price?.currency?.label,
                            displayPrice: price?.rdSellingPrice?.amount / price?.rdSellingPrice?.divisor
                        },
                        productInfo: {
                            productID: partNumber,
                            productName: name,
                            manufacturer: designerName,
                            ...(attributes?.gender ? { gender: attributes?.gender } : {})
                        },
                        attributes: {
                            position: index + 1,
                            recType: productList?.algorithm,
                            recId: 'just in v1'
                        }
                    }
                ]
            }
        })
    }

    onArrowClick(dir: 'next' | 'previous'): void {
        this.track({
            event: `product carousel - ${dir}`,
            category: {
                primaryCategory: 'ecommerce',
                subCategory: this.gtmConfig.location
            }
            // TODO
            // attributes: {
            //   position: "" //the set of products you are clicking through to //e.g. "5-8"
            // }
        })
    }

    onCTAClick(): void {
        this.track({
            event: 'just in carousel - view all link',
            effect: 'navigate',
            category: {
                primaryCategory: this.gtmConfig.location,
                subCategory: 'just in'
            }
        })
    }
}
