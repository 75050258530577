import React, { useEffect, useRef, useState } from 'react';
import { createComponent } from '@ycos/fela'
import ProductCard from './ProductCard'
import { ProductList, MIN_PRODUCTS } from '../../domain/types/ProductList'
import type {ProductListAnalytics} from '../../domain/analytics/ProductListAnalytics'
import TextCard from './TextCard';
import { CardWrapper, DesktopOnlyCardContainer} from './CardWrapper';
import {MobileTextCard} from './MobileTextCard'
import RightArrow from './icons/grey-arrows-large-right.js'
import LeftArrow from './icons/grey-arrows-large-left.js'
import debounce from 'lodash.debounce'
import { fadeInAnimation } from './Animaton';
import { Locale } from '../../domain/types/core';
import getBreakpoint from '../../utils/getBreakpoint'

interface ProductListProps {
  productList: ProductList
  tracking: ProductListAnalytics
  locale: Locale
  carouselCopy?: {
    titleText?: string
    personalisedText?: string
    linkText?: string
  },
  targetHref: string
}
const CardList = createComponent(
  'CardList',
  () => ({
    scrollSnapType: 'x proximity',
    overflowX: 'scroll',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    width: '100%',
    '::-webkit-scrollbar': {
        display: 'none',
        '-webkit-appearance': 'none',
        width: 0,
        backgroundColor: 'transparent'
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: 'transparent'
      },

  }),
  'div'
)

export const Wrapper = createComponent(
  'Wrapper',
  ({ theme }) => ({
    display: 'flex',
    maxWidth: "min(100vw, 1376px)",
    backgroundColor: theme.typography.colors.SecondaryLightestGrey,
    'screen-large': {
      margin: `0 ${theme.spacingMultiplier * 4}px`,
      padding: '0'
    },
    paddingLeft: '12px',
  }),
  'div'
)
const Button = createComponent(
  'Button', ({theme, dir}) => ({
    border: 'none', outline: 'none', width: '48px', fontSize: '24px',
    backgroundColor: theme.typography.colors.SecondaryLightestGrey,
    color: theme.typography.colors.PrimaryGrey,
    cursor:'pointer',
    display: 'none',
    'screen-large': {
      display: 'block',
      marginRight:dir==="next" ? "8px !important":0,
      marginLeft:dir==="prev" ? "8px !important":0,
      ...fadeInAnimation
    }
  }), 'button'
)

type ProductCardList = {
  layoutVariant: string[]
} & React.FC<ProductListProps>

const ProductCardList: ProductCardList = ({ carouselCopy, productList, locale, tracking, targetHref }) => {
  const wrapperRef = useRef(null);
  const [leftArrowDisabled, setLeftArrowDisabled] = useState(true)
  const [rightArrowDisabled, setRightArrowDisabled] = useState(false)

  useEffect(() => {
    productList && tracking.onProductLoad(productList.products, productList, 'carousel')
    productList && setRightArrowDisabled(productList.products.length <= MIN_PRODUCTS && getBreakpoint(window.innerWidth) === 'Desktop-large')
  }, [productList])

  useEffect(() => {
    const wrapper = wrapperRef.current

    if (wrapper) {
      const scrollHandler = debounce(()=>{
        setLeftArrowDisabled((wrapper.scrollLeft === 0))
        setRightArrowDisabled((wrapper.scrollWidth - wrapper.offsetWidth) - wrapper.scrollLeft < 20)
      }, 50)

      wrapper.addEventListener('scroll',scrollHandler)

      return () => {
        wrapper.removeEventListener('scroll',scrollHandler)
      }
    }
  }, [wrapperRef.current])

  const move = (dir = 1) => {
    const wrapper = wrapperRef.current
    wrapper.scrollTo({
      left: wrapper.scrollLeft + (dir * wrapper.offsetWidth),
      behavior: 'smooth'
    })
    tracking.onArrowClick(dir === 1 ? "next" : "previous")
  }
  const next = ()=> move(1)
  const previous = ()=> move(-1)

  const isRTL = locale.language === 'ar'

  return (
    <div data-automation='carousel'>
      {carouselCopy && (
        <MobileTextCard href={targetHref} onClick={() => tracking.onCTAClick()} {...carouselCopy}/>
      )}
      <Wrapper>
        <Button data-automation='previous-arrow' dir="prev" onClick={previous}>
          {isRTL ? (
            <RightArrow color={rightArrowDisabled ? '#B6B6B6': '#000000'} />
          ) : (
            <LeftArrow color={leftArrowDisabled ? '#B6B6B6': '#000000'} />
          )}
          </Button>
        <CardList innerRef={wrapperRef}>
          {carouselCopy && (
            <DesktopOnlyCardContainer>
              <TextCard href={targetHref} onClick={() => tracking.onCTAClick()} {...carouselCopy}/>
            </DesktopOnlyCardContainer>
          )}
          {productList && productList.products.map((product, index) => (
            <CardWrapper key={product.partNumber} position={index}>
              <ProductCard
                onProductClick={() => tracking.onProductClick(product, index, productList, 'carousel')}
                product={product}
                locale={locale}
              />
            </CardWrapper>
          ))}
        </CardList>
        <Button data-automation='next-arrow' dir="next" onClick={next}>
          {isRTL ? (
            <LeftArrow color={leftArrowDisabled ? '#B6B6B6': '#000000'} />
          ) : (
            <RightArrow color={rightArrowDisabled ? '#B6B6B6': '#000000'} />
          )}
        </Button>
      </Wrapper>
    </div>
  )
}

ProductCardList.layoutVariant = ['osc-curated-carousel']

export default ProductCardList
