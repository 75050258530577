import { createAuthenticatedFetch } from "./util/authenticatedFetch"
import { ByPartNumbersDataLoader, CategoryProductDataLoader, ESpotProductDataLoader, PersonalisedProductDataLoader } from "./dataLoaders"
import { WCSConfig, Locale, PersonalisationConfig, OptimizelyConfig, GTMConfig } from "./types/core"
import { ProductList } from "./types/ProductList"
import { PersonalisationFlow, PersonalisationFlowOptions } from "./personalisation-flow/PersonalisationFlow"
import type { PersonalisationFlowResult } from './personalisation-flow/PersonalisationFlow'
import optimizely from "@optimizely/optimizely-sdk"
import { GTMTrackingFn } from "./analytics/gtm/types/GTMTracking"
import { UserLoader } from "./personalisation-flow/UserLoader"
import GTMProductListAnalytics from "./analytics/gtm/GTMProductListAnalytics"
import { OptimizelyFactory } from "./personalisation-flow/OptimizelyFlow"

declare global {
  interface Window {
    optimizelyCreateInstance: optimizely.Client;
  }
}

const PersonalisationFlowOptionsByGender: Record<string, PersonalisationFlowOptions> = {
  womens: {
    disabled: false,
    fallbackCategory: '/just-in'
  },
  mens: {
    disabled: true,
    fallbackCategory: '/mens/just-in'
  },
}

export class ProductLoaderSDK {
  constructor(
    private categoryProductDataLoader: CategoryProductDataLoader,
    private byPartNumbersDataLoader: ByPartNumbersDataLoader,
    private personalisationFlow: PersonalisationFlow
  ) { }

  public byCategory(category: string): Promise<ProductList> {
    return this.categoryProductDataLoader.load({ category })
  }

  public byPartNumbers(partNumbers: string[]): Promise<ProductList> {
    return this.byPartNumbersDataLoader.load({partNumbers})
  }

  public getPersonalisationFlow(gender: string):Promise<PersonalisationFlowResult>{
    const options = PersonalisationFlowOptionsByGender[gender] || PersonalisationFlowOptionsByGender.womens

    return this.personalisationFlow.run(options)
  }
}

export const createProductLoaderSDK = (
  wcsConfig: WCSConfig, locale: Locale,
  personalisationConfig: PersonalisationConfig,
  optimizelyConfig: OptimizelyConfig,
  gtmConfig: GTMConfig,
  breakpoint: string,
  hostname: string): ProductLoaderSDK => {

  const authenticatedFetch = createAuthenticatedFetch(wcsConfig)
  const categoryProductDataLoader = new CategoryProductDataLoader(
    wcsConfig,
    locale,
    authenticatedFetch
  )
  const byPartNumbersDataLoader = new ByPartNumbersDataLoader(wcsConfig, locale, authenticatedFetch)
  const personalisedProductDataLoader = new PersonalisedProductDataLoader(
    personalisationConfig,
    byPartNumbersDataLoader
  )
  const gtmAnalytics = new GTMProductListAnalytics(gtmConfig, GTMTrackingFn)
  const optimizelyFactory = new OptimizelyFactory(window?.optimizelyCreateInstance 
    ? window.optimizelyCreateInstance
    : optimizely.createInstance({
    sdkKey: optimizelyConfig.sdkKey,
    eventBatchSize: 1,
    eventFlushInterval: 100
  }))
  const browserAttributes = {
    breakpoint, hostname,
    locale: locale.locale,
    estore: `${locale.language}-${locale.country}`.toLowerCase()
  }

  const personalisationFlow = new PersonalisationFlow(
    new UserLoader(),
    categoryProductDataLoader,
    personalisedProductDataLoader,
    gtmAnalytics,
    optimizelyFactory,
    browserAttributes
  )

  return new ProductLoaderSDK(
    categoryProductDataLoader,
    byPartNumbersDataLoader,
    personalisationFlow
  )
}
