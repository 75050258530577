import { ProductDataLoader } from '../ProductDataLoader'
import type { WCSConfig, Locale } from '../../types/core'
import { ProductList } from '../../types/ProductList'
import type { AuthenticatedFetch } from '../../util/authenticatedFetch'
import { Product } from '../../types/Product'
import { Category } from './types/RawCategoryResponse'
import getGenderFromAttributes from '../../util/getGenderFromAttributes'

export type CategoryProductDataLoaderOptions = {
  category: string
}
export class CategoryProductDataLoader implements ProductDataLoader<CategoryProductDataLoaderOptions> {
  constructor(
    private wcsConfig: WCSConfig, private locale: Locale,
    private authenticatedFetch: AuthenticatedFetch<Category.Response>) { }

  private getUrl(category: string): string {
    const brandLocale = `${this.wcsConfig.brand}_${this.locale.country}`
    return `search/resources/store/${brandLocale}/productview/byCategory?attrs=true&category=${encodeURIComponent(category)}&locale=${this.locale.locale}&pageNumber=1&pageSize=16`
  }

  private mapResult(category:string, rawResponse: Category.Response): ProductList {
    return {
      dataSource: 'ByCategory' + category.split("/").join("_"),
      products: rawResponse?.products?.map(this.mapProduct)
    }
  }

  private mapProduct(product: Category.Product): Product {
    return {
      externalId: product.productColours?.[0]?.productId,
      designerIdentifier: product.designerIdentifier,
      designerName: product.designerNameEN,
      price: {
        sellingPrice: product.price?.sellingPrice,
        rdSellingPrice: product.price?.rdSellingPrice,
        currency: product.price?.currency
      },
      thumbnailUrlTemplate: product.productColours?.[0]?.imageTemplate,
      name: product.shortDescription || product.name,
      productUrl: product.seo.seoURLKeyword,
      imageViews: product.productColours?.[0]?.imageViews,
      buyable: product.buyable,
      displayable: product.visible,
      partNumber: product.partNumber,
      attributes: {
        gender: getGenderFromAttributes(product.attributes),
      }
    }
  }

  async load(options: CategoryProductDataLoaderOptions): Promise<ProductList> {
    const result = await this.authenticatedFetch(this.getUrl(options.category))
    return this.mapResult(options.category, result)
  }
}
