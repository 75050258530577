const getBreakpoint = (viewportWidth) => {
  if (viewportWidth >= 1440) {
    return 'Desktop-large'
  } else if (viewportWidth >= 1024) {
    return 'Desktop'
  } else if (viewportWidth >= 768) {
    return 'Tablet'
  }
  return 'Mobile'
}

export default getBreakpoint
