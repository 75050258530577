import { createComponent } from '@ycos/fela'
import React from 'react'
import { BigHeader, BodyText, LinkWrapper, LinkText} from './TextComponents'
import { fadeInAnimation} from './Animaton'

const Wrapper = createComponent( 'Wrapper', ({theme}) => ({
  display: 'flex',
  flexDirection:'column',
  justifyContent: 'center',
  textAlign: 'left',
  'screen-xlarge' : {
      flex: '1 0 16.6%'
    },
  'screen-large' : {
      flex: '1 0 20%'
    },
  height: '100%',
  padding: `0 ${theme.spacingMultiplier * 3}px 0 ${theme.spacingMultiplier * 2}px`,
  backgroundColor: theme.typography.colors.SecondaryLightestGrey,
  ...fadeInAnimation
}), 'div' )

export const TextSpacer = createComponent(
  'TextSpacer',
  ({ theme }) => ({
    marginBottom: `${theme.spacingMultiplier * 3}px`
  }),
  'div'
)
interface TextCardProps {
  titleText?: string,
  personalisedText?: string,
  linkText?: string,
  href: string,
  onClick?: () => void
}

const TextCard: React.FC<TextCardProps> = ({ titleText, href, personalisedText, linkText, onClick}) => (
  <Wrapper>
    {titleText && (
      <BigHeader data-automation='title-text'>
        {titleText}
      </BigHeader>
    )}
    {personalisedText && (
      <TextSpacer>
        <BodyText data-automation='personalised-text'>
          {personalisedText}
        </BodyText>
      </TextSpacer>
    )}
    {linkText && href && (
      <LinkWrapper data-automation='link-text' href={href} onClick={onClick}>
        <LinkText>
          {linkText}
        </LinkText>
      </LinkWrapper>
    )}
  </Wrapper>
)

export default TextCard
