import { ArrowClick } from "./onArrowClick"
import { CTAClick } from "./onCTAClick"
import { ProductClick } from "./onProductClick"
import { ProductListLoad } from "./onProductLoad"

declare global {
    interface Window {
        dataLayer: [object]
    }
}

export type EventType = ProductListLoad | ProductClick | ArrowClick | CTAClick

export type GTMTracking = (eventType: EventType) => void

export const GTMTrackingFn = (analytics: EventType): void => {
    if (window.dataLayer) {
        window.dataLayer.push(analytics as object)
    }
}